import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import TokenTable from "~/components/TokenTable/TokenTable";
export const _frontmatter = {
  "menuLabel": "Depth",
  "sortOrder": 6.2
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Depth`}</h1>
    <p className="p-large">Elevate components with drop shadows to create hierarchy and emphasis.</p>
    <p>{`Our shadow system consists of 24 levels in case we need to show plenty of depth in our UI. The styles are minimal because sportsbook's can be compact and dense with information.`}</p>
    <blockquote>
      <p parentName="blockquote">{`We've scoped the levels down into a collection you can use: `}<a parentName="p" {...{
          "href": "/tokens/box-shadow"
        }}>{`shadow tokens.`}</a>{` `}</p>
    </blockquote>
    <TokenTable tokenGroup="Shadow" mdxType="TokenTable" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      